<template>
  <div id="Online-Consultant">
    <iframe
      id="Online-Consultant-Iframe"
      :src="`${$host}/server/kefu.php?a=621276866&fn=${user.real_name}&id=${user.certificate}`"
      frameborder="0"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    return { user };
  },
  methods: {},
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Online-Consultant {
  margin-left: -0.26rem;
  margin-right: -0.26rem;
  overflow: hidden;
  height: 100%;

  > iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
